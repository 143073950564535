import React, {Component, Fragment} from 'react';
import {Link} from "react-router-dom";
import authService from '../services/AuthorizeService';
import Loader from "../components/Loader";
import moment from "moment";
import StatusControl from "../components/StatusControl";

require('moment/locale/ru');

export class Devices extends Component {
  static displayName = Devices.name;

  constructor(props) {
    super(props);
    this.state = {
      devices: [],
      loading: true
    };
  }

  componentDidMount() {
    this.populateDevicesData();
  }

  renderForecastsTable() {
    return (
      <Fragment>

        <table className="table">
          <thead>
          <tr>
            <td>Устройство</td>
            <td>Серийный номер</td>
            <td>Серийный номер ЧС</td>
            <td>Описание</td>
            <td>Последняя активность</td>
            <td>Статус</td>
          </tr>
          </thead>
          <tbody>
          {
            this.state.devices.map((device, id) => (
              <tr key={device.id}>
                <td>
                  <Link to={`/devices/${device.id}`}>
                    {/*{device.id}*/}
                    {!!device.model
                      ? `${device.model}${!!device.firmwareVersion ? ` (${device.firmwareVersion})` : ''}`
                      : device.id}
                  </Link>
                </td>
                <td>
                  {device.serialNumber}
                </td>
                <td>
                  {device.clockSerialNumber}
                </td>
                <td>
                  {device.description}
                </td>
                <td>
                  <Link to={`/devices/${device.id}/logs`}>
                    {moment(device.lastOnlineTime).fromNow()}
                  </Link>

                </td>
                <td>
                  <StatusControl status={device.isConnected ? "online" : "offline"}/>
                </td>
              </tr>
            ))
          }
          </tbody>
        </table>

      </Fragment>
    );
  }

  render() {
    let contents = this.state.loading
      ? <Loader/>
      : this.renderForecastsTable();

    return (
      <div>
        <h1 id="tabelLabel">Управление устройствами</h1>
        {contents}
      </div>
    );
  }

  async populateDevicesData() {
    const response = await fetch('/api/devices', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await authService.isAuthenticated() ? await authService.getAccessToken() : ""}`
      }
    });

    if (!response.ok) {
      this.setState({loading: false});
      return;
    }

    const data = await response.json();
    this.setState({devices: data, loading: false});
  }
}